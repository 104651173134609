import React from 'react'
import 'braft-editor/dist/index.css'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Upload, message, Icon, Popconfirm } from 'antd';
import intl from 'react-intl-universal';
import "../shopSetup/setup.less"
import { AddAdmin, GetAdminList, putAdmin, deleteAdmin } from "../../action/authAction";

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}

class NewAdmin extends React.Component {

    state = {
        is_show: true,
        imageUrl: "",
    };

    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };


    SubmitData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }

        }
        if (this.state.logo_file) {
            formData.append("head_image", this.state.logo_file)

        }
        AddAdmin(formData).then(res => {
            this.props.history.push("/admin")
        })
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.is_show = this.state.is_show ? "1" : "0"
                this.SubmitData(values)
            }
        });
    };


    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );
        const { is_show, imageUrl } = this.state;

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={<span>{intl.get('user.table.header')}</span>}
                >
                    <Upload
                        name="avatar"
                        accept=".jpg,.png,.jpeg"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={this.handleChange}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>

                <Form.Item
                    label={intl.get('admin.table.aname')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true }],
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={<span>{intl.get('admin.table.passward')}</span>}
                >
                    {getFieldDecorator('password', {
                        rules: [{ required: true }, { max: 16, min: 6, message: intl.get('admin.table.passward_error') }],
                        getValueFromEvent: (event) => {
                            return event.target.value.replace(/\W/g, '')
                        }
                    })(<Input />)}
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>

        );
    }
}

export default withRouter(Form.create()(NewAdmin))
