import React from "react";
import { withRouter } from 'react-router-dom'
import { Input, Table, Icon, Popconfirm, Form, Button, Select, DatePicker, Modal, Switch, Tooltip, Popover } from 'antd';
import { getOrderBlindboxList, getRefundOrderList, deleteOrder, manualPrintOrder, getPrinterList, putOrderStatus, confirmOrderOrder, GetOrderExportSecret, ExportOrderList } from "../../action/goodAction";
import { UpdateShopMessage, getPartner, GetConfigSet } from "../../action/authAction"
import intl from 'react-intl-universal';
import moment from 'moment';
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
let currency_symbol = sessionStorage.getItem("currency_symbol")

class orderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingKey: '',
            printerList: "",
            printerId: "",
            meta: "",
            url: "",
            one_url: "",
            two_url: "",
            visible: false,
            setVisible: false,
            value: "",
            is_error: false,
            itemId: "",
            spu_discount_id: "",
            is_show_order: false,
            is_order_receiving: false,
            is_print_off: false,
            is_printer: false,
            orderValue: "",
            phoneValue: "",
            partner_id: "",
            partner_name: "",
            is_discount: "",
            is_coupon: "",
            min_pay_fee_on_online: "",
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '4%',
                align: "center",
            },
            
            {
                title: intl.get('home.table.ordernumber'),
                dataIndex: 'order_sn',
                width: '8%',
                align: "center",
            },


            {
                title: '封面',
                width: '10%',
                align: "center",
                render: (record) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={record.blindstore?record.blindstore.image:"-"} alt="" />
                    )
                }
            },



            {
                title: intl.get('nav.CostFlow.name'),
                width: '10%',
                align: "center",
                render: (text, record) => { 
                        return <div style={{ display: "flex" }}> 
                            <div>
                                {record.blindstore?record.blindstore.name:"-"}
                            </div>
                        </div>  
                }
            },
             
            {
                title: intl.get('home.table.price'),
                dataIndex: 'price',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>{currency_symbol}{record}</span>
                }
            },
           
            {
                title: intl.get("home.table.ordertime"),
                width: '8%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
            },
             
            {
                title: intl.get('home.table.operation'),
                dataIndex: 'operation',
                width: '6%',
                align: "center",
                render: (text, record) => {
                    return <Popover
                        style={{ width: 500 }}
                        content={
                            <div style={{ display: "flex" }}>
                                {
                                    record.order_status === "for_consumption" &&
                                    <Popconfirm title={intl.get('home.table.operation.btn.isOrder')}
                                        style={{ marginRight: "5px" }}
                                        onConfirm={() => {
                                            confirmOrderOrder({ "unique_code": record.unique_code }).then(res => {
                                                this.HandleGetData(this.state.spu_discount_id, this.state.url, 1)
                                            })
                                        }}>
                                        <Tooltip title={intl.get('home.table.operation.btn.isOrder')}>
                                            <Button
                                                style={{ marginRight: "5px" }}
                                                shape="circle"
                                                icon="check-circle"
                                            />
                                        </Tooltip>
                                    </Popconfirm>
                                }
                                {
                                    record.order_status === "wait_order_receiving" ?
                                        <div>
                                            <Tooltip title={intl.get('home.table.operation.btn.refuse')}>
                                                <Button
                                                    style={{ marginRight: "5px" }}
                                                    shape="circle"
                                                    icon="stop"
                                                    onClick={() => {
                                                        this.setState({
                                                            visible: true,
                                                            itemId: record.id
                                                        })
                                                        // this.props.history.push("/OrderDetail/" + record.id)
                                                    }} />
                                            </Tooltip>

                                            <Popconfirm title={intl.get('home.table.operation.btn.isAgree')}
                                                style={{ marginRight: "5px" }}
                                                onConfirm={() => {
                                                    putOrderStatus(record.id, { "status": 1 }).then(res => {
                                                        this.HandleGetData(this.state.spu_discount_id, this.state.url, 1)
                                                    }).catch(error => {
                                                        console.log(error.response)
                                                    })
                                                }}>
                                                <Tooltip title={intl.get('home.table.operation.btn.agree')}>
                                                    <Button
                                                        style={{ marginRight: "5px" }}
                                                        shape="circle"
                                                        icon="check-circle"
                                                    />
                                                </Tooltip>
                                            </Popconfirm>
                                        </div>
                                        :
                                        <div>
                                            {
                                                this.state.is_printer && this.state.is_print_off &&
                                                <Popconfirm title={intl.get('home.table.operation.btn.printHorder')}
                                                    style={{ marginRight: "5px" }}
                                                    onConfirm={() => {
                                                        manualPrintOrder(record.id, { "w": "1" }).then(res => {
                                                        })
                                                    }}>
                                                    <Tooltip title={intl.get('home.table.operation.btn.print')}>
                                                        <Button
                                                            style={{ marginRight: "5px" }}
                                                            shape="circle"
                                                            icon="printer"
                                                        />
                                                    </Tooltip>
                                                </Popconfirm>
                                            }
                                        </div>


                                }
                                <Tooltip title={intl.get('home.table.operation.btn.view')}>
                                    <Button
                                        shape="circle"
                                        icon="eye"
                                        onClick={() => {
                                            this.props.history.push("/BlindboxOrderDetail/" + record.id)
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover>
                },
            },

        ];
    }

    async componentDidMount() {
        const id = this.props.location.pathname.substring(11)
        let shopData = await getPartner()
        let configData = await GetConfigSet()
        this.setState({
            url: "",
            spu_discount_id: id,
            is_show_order: shopData.data.is_show_order === 1 ? true : false,
            is_order_receiving: shopData.data.is_order_receiving === 1 ? true : false,
            is_print_off: shopData.data.is_print_off === 1 ? true : false,
            is_printer: shopData.data.is_printer === 1 ? true : false,
            partner_id: shopData.data.id,
            partner_name: shopData.data.name,
            is_discount: shopData.data.is_discount,
            is_coupon: shopData.data.is_coupon,
            min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,
        }, () => {
            this.HandleGetData(this.state.spu_discount_id, this.state.url, 1)
        })
    }

    async HandleGetData(spu_discount_id, data, page) {
        let res = await getOrderBlindboxList(spu_discount_id, data, page);
        let resp = await getPrinterList()
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination,
            printerList: resp.data.data,
            printerId: resp.data.data && resp.data.data[0] && resp.data.data[0].id
        })
    }
    async HandleGetRefoundData(spu_discount_id, data, page) {
        let res = await getRefundOrderList(spu_discount_id, data, page);
        let resp = await getPrinterList()
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination,
            printerList: resp.data.data,
            printerId: resp.data.data && resp.data.data[0] && resp.data.data[0].id
        })
    }

    async handleDelete(data) {
        await deleteOrder(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    HandleExportOrder = () => {
        let { partner_id } = this.state
        GetOrderExportSecret().then(res => {
            let url = "?partner_id=" + partner_id + "&secret=" + res.data
            ExportOrderList(url).then(data => {
                this.downLoadDataQuery(data.data)
            })
        })



    }

    downLoadDataQuery = (resData) => {
        let fileName = this.state.partner_name + "_" + this.state.partner_id
        const blob = new Blob([resData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        // const blob = new Blob(['\uFEFF' + resData], { type: 'application/vnd.ms-excel;charset=utf-8' });
        const downloadElement = document.createElement('a');
        const href = URL.createObjectURL(blob); //创建下载链接
        downloadElement.href = href;
        downloadElement.download = fileName + '.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);// 下载完成移除元素
        window.URL.revokeObjectURL(href)
    }


    render() {
        const { data, meta, url, one_url, two_url, value, is_error, itemId, spu_discount_id, is_show_order, orderValue, phoneValue, is_order_receiving, is_discount, is_coupon, min_pay_fee_on_online } = this.state
        const columns = this.columns.map(col => {
            return {
                ...col,
                onCell: record => ({
                    record,
                    title: col.title,
                }),
            };
        });
        return (
            <div>
                <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
                     
                    <div style={{ display: 'flex', flex: 1, justifyContent: "space-between", marginBottom: "10px" }}>
                        <Search
                            style={{ width: "32%" }}
                            placeholder={intl.get("home.placehorder")}
                            enterButton={intl.get("home.search")}
                            value={orderValue}
                            onChange={(e) => {
                                this.setState({
                                    orderValue: e.target.value,
                                })
                            }}
                            onSearch={(val) => {
                                this.setState({
                                    url: "&order_sn=" + val + one_url + two_url,
                                    phoneValue: "",
                                }, () => {
                                    this.HandleGetData(spu_discount_id, this.state.url, 1)
                                })
                            }}
                        />
                        <Search
                            style={{ width: "32%" }}
                            placeholder={intl.get("home.placehorder_p")}
                            enterButton={intl.get("home.search")}
                            value={phoneValue}
                            onChange={(e) => {
                                this.setState({
                                    phoneValue: e.target.value
                                })
                            }}
                            onSearch={(val) => {
                                this.setState({
                                    url: "&phone=" + val + one_url + two_url,
                                    orderValue: "",
                                }, () => {
                                    this.HandleGetData(spu_discount_id, this.state.url, 1)
                                })
                            }}
                        />
                        <RangePicker style={{ width: "32%" }}
                            format="YYYY-MM-DD"
                            onChange={(value) => {
                                let start_at = value[0] ? moment(value[0]).format("YYYY-MM-DD") + " 00:00:00" : ""
                                let end_at = value[1] ? moment(value[1]).format("YYYY-MM-DD") + " 23:59:59" : ""
                                this.setState({
                                    url: "&start_at=" + start_at + "&end_at=" + end_at + one_url + two_url,
                                    orderValue: "",
                                    phoneValue: "",
                                }, () => {
                                    this.HandleGetData(spu_discount_id, this.state.url, 1)
                                })
                            }} />
                    </div>


                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        
                        <div style={{ marginBottom: "5px" }}>


                            <Button
                                style={{ marginRight: "5px", backgroundColor: (url.indexOf("is_discount_order") > -1) || (url.indexOf("is_user_coupon") > -1) ? "" : "#5867dd", color: (url.indexOf("is_discount_order") > -1) || (url.indexOf("is_user_coupon") > -1) ? "rgba(0, 0, 0, 0.65)" : "#fff" }}
                                onClick={() => {
                                    this.setState({
                                        url: "",
                                        one_url: "",
                                        two_url: "",
                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.columns.splice(5, 1, {
                                            title: intl.get("home.table.ordertime"),
                                            width: '8%',
                                            align: "center",
                                            render: (record) => {
                                                return record.created_at && record.created_at.date.substring(0, 19)
                                            }
                                        })
                                        this.HandleGetData(spu_discount_id, this.state.url, 1)
                                    })
                                }}>
                                {intl.get('home.table.useStatus.all')}
                            </Button>
 

                            <Button
                                style={{ marginRight: "5px", backgroundColor: two_url === "&is_pay=1" ? "#5867dd" : "", color: two_url === "&is_pay=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: one_url,
                                        two_url: "&is_pay=1",
                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.columns.splice(5, 1, {
                                            title: intl.get("home.table.time"),
                                            width: '8%',
                                            align: "center",
                                            render: (record) => {
                                                return record.pay_at && record.pay_at.date.substring(0, 19)
                                            }
                                        })
                                        this.HandleGetData(spu_discount_id, this.state.url, 1)
                                    })
                                }}>
                                {intl.get('home.table.useStatus.paid')}
                            </Button>
                            <Button
                                style={{ marginRight: "5px", backgroundColor: two_url === "&order_status=for_consumption" ? "#5867dd" : "", color: two_url === "&order_status=for_consumption" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: one_url + "&order_status=for_consumption",
                                        two_url: "&order_status=for_consumption",
                                        orderValue: "",
                                        phoneValue: "",
                                    }, () => {
                                        this.columns.splice(5, 1, {
                                            title: intl.get("home.table.time"),
                                            width: '8%',
                                            align: "center",
                                            render: (record) => {
                                                return record.pay_at && record.pay_at.date.substring(0, 19)
                                            }
                                        })
                                        this.HandleGetData(spu_discount_id, this.state.url, 1)
                                    })
                                }}>
                                已抽取
                            </Button>

                            
                           
                        </div>
                    </div>
                     

                </div>
                <Table
                    rowKey={record => record.id}
                    dataSource={data}
                    columns={columns}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData(spu_discount_id, this.state.url, e.current)
                    }}
                    className="waperBox"
                    scroll={{ x: 800 }}
                />
                <Modal
                    title={intl.get("home.table.reject_the_order")}
                    visible={this.state.visible}
                    onOk={() => {
                        if (value === "deny") {
                            putOrderStatus(itemId, { "status": 6 }).then(res => {
                                this.setState({
                                    visible: false
                                })
                                this.HandleGetData(spu_discount_id, this.state.url, 1)
                            })
                        } else {
                            this.setState({ is_error: true })
                        }
                    }}
                    onCancel={() => this.setState({ visible: false })}
                >
                    <div>
                        <span>{intl.get("home.table.confirm_reject")}</span>
                        <Input
                            style={{ marginTop: "20px" }}
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    value: e.target.value.toLowerCase()
                                })
                            }} />
                        {
                            is_error && <span>{intl.get("seat.table.please")}"DENY"</span>
                        }

                    </div>

                </Modal>
                <Modal
                    title={intl.get("behavior.Order.amount.setting")}
                    visible={this.state.setVisible}
                    footer={null}
                    onCancel={() => this.setState({ setVisible: false })}
                >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                        <span>{intl.get("behavior.Online.payment.order")}：</span>
                        <Input
                            style={{ width: "200px" }}
                            disabled
                            prefix={currency_symbol}
                            value={min_pay_fee_on_online}
                        />
                    </div>

                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(orderList))

