import intl from 'react-intl-universal';

export function data() {
    return [
        {
            "name": intl.get("nav.datastatics"),
            "icon": "appstore",
            "children": [
                {
                    "name": intl.get("nav.datastatics"),
                    "path": "/dataStatistics",
                }
            ],
        },

        
       

        
        {
            "name": intl.get('setup.form.shop'),
            "icon": "shop",
            "children": [
 
                {
                    "name": intl.get('setup.form.shop'),
                    "children": [
                        {
                            "name": '店铺列表',
                            "path": "/Shop",
                        },

                        {
                            "name": '套餐申请记录',
                            "path": "/applyPackageList",
                        },


                        
                    ]
                }, 
            ],
        },


         {
            "name": intl.get('nav.user'),
            "icon": "team",
            "children": [
                {
                    "name": intl.get("nav.user"),
                    "path": "/user",
                }
            ],
        },





        /* 订单
        {
            "name": intl.get('nav.orderManagement.list'),
            "icon": "snippets",
            "children": [
                {
                    "name": intl.get("nav.orderManagement.list"),
                    "path": "/OrderList",
                }
            ],
        },
        */


          {
            "name": "盲盒",
            "icon": "gift",
            "children": [
                 

                 {
                    "name": "盲盒",
                    "children": [


                        // {
                        //     "name": '商品列表',
                        //     "path": "/BlindBoxGoodList",
                        // },

                        {
                            "name": '盲盒列表',
                            "path": "/BlindBoxList",
                        }, 

                        // {
                        //     "name": '商品日志',
                        //     "path": "/operationLog",
                        // }, 


                    ]
                }, 


                {
                    "name": "订单管理",
                    "children": [

                        {
                            "name": '盲盒购买订单',
                            "path": "/BlindBoxOrderList",
                        },  
                    ]
                }, 




            ],
        },


        




        {
            "name": intl.get('nav.goodsManagement'),
            "icon": "trophy",
            "children": [
                {
                    "name": intl.get('nav.goodsManagement.goods.goodslist'),
                    "path": "/goodsList",
                },



                /*

                {
                    "name": intl.get('nav.goodsManagement.classify.classifyList'),
                    "path": "/cateList",
                },


                {
                    "name": intl.get('nav.goodsManagement.specifications'),
                    "path": "/goodsSpecificationList",
                },
                */






                // {
                //     "name": intl.get('nav.goodsManagement.specifications'),
                //     "children": [
                //         {
                //             "name": intl.get('nav.goodsManagement.specifications'),
                //             "path": "/goodsSpecificationList",
                //         },
                //         {
                //             "name": intl.get('nav.goodsManagement.itemspecifications'),
                //             "path": "/goodsSpecificationItemList",
                //         }
                //     ],
                // },
                {
                    "name": intl.get('nav.goodsManagement.additional.list'),
                    "path": "/goodsAnnexTypeList",
                },
                // {
                //     "name": intl.get('nav.goodsManagement.additional'),
                //     "children": [
                //         {
                //             "name": intl.get('nav.goodsManagement.additional.list'),
                //             "path": "/goodsAnnexTypeList",
                //         },
                //         {
                //             "name": intl.get('nav.goodsManagement.itemadditional.list'),
                //             "path": "/goodsAnnexTypeItemList",
                //         }
                //     ],
                // },
            ],
        },




        
        {
            "name": "Deal",
            "icon": "deal",
            "children": [
                {
                    "name": "Deal",
                    "path": "/promotionalgoodsList",
                },
            ]
        },
        




        {
            "name": intl.get('nav.goodsManagement.promotionalgoods.coupon'),
            "icon": "coupon",
            "children": [
                {
                    "name": intl.get('nav.goodsManagement.promotionalgoods.coupon'),
                    "path": "/couponList",
                },
            ]
        },



        /* 店铺 & 套餐
        {
            "name": intl.get('setup.form.shop'),
            "icon": "shop",
            "children": [

                {
                    "name": intl.get('nav.setting.businessHours'),
                    "children": [
                        {
                            "name": intl.get('nav.setting.businessHours'),
                            "path": "/businessHours",
                        },
                        // {
                        //     "name": intl.get('nav.setting.public_holidays'),
                        //     "path": "/businessHoliday",
                        // },
                        {
                            "name": intl.get('nav.setting.holiday_business'),
                            "path": "/holidayBusinessList",
                        },
                    ]
                },

                {
                    "name": intl.get('nav.setting.notice.list'),
                    "children": [
                        {
                            "name": intl.get('nav.setting.notice.list'),
                            "path": "/AnnouncementList",
                        },
                        {
                            "name": intl.get('nav.setting.notice.system'),
                            "path": "/SystemAnnouncement",
                        },
                    ]
                },
                {
                    "name": intl.get("nav.album"),
                    "path": '/AlbumList',
                },
                {
                    "name": intl.get('nav.behavior'),
                    "path": "/behavior"
                },
                {
                    "name": intl.get('home.table.way.name_d'),
                    "path": "/toStore"
                },


                {
                    "name": intl.get('setup.form.delivery'),
                    "children": [
                        {
                            "name": intl.get('setup.form.delivery'),
                            "path": "/delivery"
                        },
                        {
                            "name": intl.get('nav.setting.freight'),
                            "path": "/freightList",
                        },
                    ]
                },
            ],
        },


        {
            "name": intl.get('nav.package'),
            "icon": "package",
            "children": [
                {
                    "name": intl.get('nav.package.list'),
                    "path": "/package",
                },
                // {
                //     "name": intl.get('nav.package.currentPackage'),
                //     "path": "/packageDetail",
                // },
                // {
                //     "name": intl.get('nav.package.packageLog.list'),
                //     "path": "/packageLog",
                // },
                {
                    "name": intl.get('setup.form.licence'),
                    "path": "/licence",
                },
            ],
        },

        */






        {
            "name": intl.get('nav.account'),
            "icon": "user",
            "noShow": true,
            "children": [
                {
                    "name": intl.get('nav.setting'),
                    "children": [
                        {
                            "name": intl.get('nav.setting.base'),
                            "path": "/setup",
                        },
                        {
                            "name": intl.get('nav.setting.password'),
                            "path": "/editpassword",
                        },

                        {
                            "name": intl.get('nav.setting.management'),
                            "path": "/admin",
                            "icon": "user",
                        },

                        {
                            "name": intl.get("nav.setting.lang"),
                            "path": "/languageList",
                        },
 
                       
                        
                        /*
                        {
                            "name": intl.get("nav.setting.printer"),
                            "path": "/PrinterList",
                        },
                        {
                            "name": intl.get('nav.setting.seat'),
                            "path": "/seatList",
                        },
                        */




                    ],
                },


                /*
                {
                    "name": intl.get('nav.money'),
                    "children": [
                        {
                            "name": intl.get('nav.CostFlow.order'),
                            "path": '/OrderFeeList'
                        },
                        {
                            "name": intl.get('home.table.partner_charge_money'),
                            "path": '/OrderChargeFeeList'
                        },
                        {
                            "name": intl.get('nav.CostFlow.other'),
                            "path": '/AnnualFeeList'
                        },
                        {
                            "name": intl.get("nav.PaySet"),
                            "path": '/PaymentConfig'
                        }
                    ],
                },

                {
                    "name": intl.get("nav.setting.SiteLocation"),
                    "children": [
                        {
                            "name": intl.get("setup.form.region"),
                            "path": "/SiteCountry",
                        },
                        {
                            "name": intl.get("nav.setting.SiteSuburb"),
                            "path": "/salesArea",
                        },
                    ]
                },
                {
                    "name": intl.get("nav.plugin.third_party_video_platform"),
                    "path": "/upLiveToken",
                },
                */






            ],
        },



        /* 插件 & 下载
        {
            "name": intl.get("nav.plugin"),
            "icon": "interaction",
            "children": [
                {
                    "name": intl.get("nav.plugin"),
                    "children": [
                        {
                            "name": intl.get("nav.plugin.currency"),
                            "path": "/currencyPlug",
                        },
                        {
                            "name": intl.get("nav.goodsManagement.goods"),
                            "path": "/goodsPlug",
                        },
                        {
                            "name": intl.get("home.table.way.name_d"),
                            "path": "/toStorePlug",
                        },

                        {
                            "name": intl.get("nav.plugin.deliveryPlug"),
                            "path": "/deliveryPlug",
                        },
                    ],
                }
            ],
        },

        {
            "name": intl.get("nav.download.app_download"),
            "icon": "mobile",
            "children": [
                {
                    "name": intl.get("nav.download.app_download"),
                    "path": "/download",
                }
            ],
        },
        */





    ]

}
