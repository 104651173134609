import React from 'react'
import { withRouter } from 'react-router-dom'
import { Descriptions, Card, Divider, Table, Button, Modal, Input, Popconfirm } from 'antd';
import { getOrderDetail ,getOrderBlindboxDetail } from "../../action/goodAction"
import intl from 'react-intl-universal';
const { TextArea } = Input;
let currency_symbol = sessionStorage.getItem("currency_symbol")
class OrderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: "", is_show: false, order_gst: 0 };
    }
    async componentDidMount() {
        const id = this.props.location.pathname.substring(21)
        let res = await getOrderBlindboxDetail(id);
        let orderSpuData = [] ;//res.data.orderSpu.data
        let sum = 0
        if (orderSpuData) {
            for (let i in orderSpuData) {
                sum += parseFloat(orderSpuData[i].gst_total)
            }
        }
        this.setState({ data: res.data, order_gst: sum })

    }


    render() {
        const { data, order_gst } = this.state;
        // const orderSpu = data.orderSpu && data.orderSpu.data
        // let serviceType = ""
        // if (data.service_type === 1) {
        //     serviceType = intl.get('home.table.way.name_d')
        // } else if (data.service_type === 2) {
        //     serviceType = intl.get('home.table.way.name_w')
        // }
        // else if (data.service_type === 3) {
        //     serviceType = intl.get('home.table.way.name_s')
        // } else if (data.service_type === 4) {
        //     serviceType = intl.get('home.table.way.name_t')
        // }
        const columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '10%',
                align: "center",
            },
            {
                title: 'bdid',
                dataIndex: ['attach_data', 'bdid'],
                width: '10%',
                align: "center",
            },
            {
                title: intl.get("home.table.goods"),
                width: '25%',
                render: (text, record) => {
                    return (
                        <div key={record.id} style={{ display: "flex" }}>
                            <img src={record.attach_data.image} style={{ width: "50px", height: "50px", marginRight: "10px", objectFit: "cover" }} alt="" />
                            <div>
                                <p>{record.attach_data.name}</p>
                                <div style={{ display: "flex" }}>
                                    {
                                        record.attach_data && record.attach_data.spu_sku && record.attach_data.spu_sku.spu_sku_goods_specification_item && record.attach_data.spu_sku.spu_sku_goods_specification_item.map(key => {
                                            return (
                                                <p key={key.id} style={{ marginLeft: 5, color: "#878FA5", fontSize: 12 }}>{key.name}</p>
                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    {
                                        record.attach_data && record.attach_data.spu_goods_annex_type && record.attach_data.spu_goods_annex_type.map(key => {
                                            let spu_goods_annex_type_item = key.spu_goods_annex_type_item
                                            return (
                                                <div>
                                                    {spu_goods_annex_type_item && spu_goods_annex_type_item.map((type_key, i) => {

                                                        let goods_annex_type_item = type_key.goods_annex_type_item
                                                        return <p key={i + goods_annex_type_item.name} style={{ marginLeft: 5, color: "#878FA5", fontSize: 12 }}>{goods_annex_type_item.name} x {goods_annex_type_item.quantity}</p>
                                                    })}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            }, {
                title: intl.get("orders.price"),
                dataIndex: 'price',
                width: '10%',
                align: "center",
                render: (record) => {
                    return currency_symbol + record
                }
            }, {
                title: intl.get("orders.count"),
                dataIndex: 'quantity',
                width: '10%',
                align: "center",
            }
        ]

        let order_status = ""
        let scan_code_type = ""
        switch (data.order_status) {
            case "un_pay":
                order_status = intl.get("home.table.useStatus.un_pay");
                break;
            case "wait_order_receiving":
                order_status = intl.get("home.table.useStatus.wait_order_receiving");
                break;
            case "un_order_receiving":
                order_status = intl.get("home.table.useStatus.un_order_receiving");
                break;
            case "for_consumption":
                order_status = intl.get("home.table.useStatus.for_consumption");
                break;
            case "finished":
                order_status = intl.get("home.table.useStatus.finished");
                break;
            case "canceled":
                order_status = intl.get("home.table.useStatus.canceled");
                break;
            case "rejected":
                order_status = intl.get("home.table.useStatus.rejected");
                break;
            case "refunded":
                order_status = intl.get("home.table.useStatus.refunded");
                break;
        }

        switch (data.scan_code_type) {
            case 1:
                scan_code_type = intl.get("nav.setting.seat")
                break;
            case 2:
                scan_code_type = intl.get("nav.goodsManagement.sales_promotion")
                break;
            case 3:
                scan_code_type = intl.get("nav.setting.seat") + "、" + intl.get("nav.goodsManagement.sales_promotion")
                break;
        }


        let spu_discount = data?.orderSpu?.data[0]?.attach_data?.spu_discount
        let oneUserWithdrawLog = data.oneUserWithdrawLog
        let userCoupon = data.userCoupon && data.userCoupon.data[0]
        let coupon_status = ""
        switch (userCoupon && userCoupon.status) {
            case "used":
                coupon_status = <span style={{ color: "green" }}>{intl.get("home.table.useStatus.name_y")}</span>
                break;
            case "unused":
                coupon_status = intl.get("home.table.useStatus.name_n")
                break;
            case "expired":
                coupon_status = <span style={{ color: "#ff0000" }}>{intl.get("home.table.useStatus.canceled")}</span>
                break;
            case "disabled":
                coupon_status = <span style={{ color: "#ff0000" }}>{intl.get("home.table.useStatus.disabled")}</span>
                break;
        }

        let orderType = ""
        if (data?.spu_discount_id) {
            orderType = intl.get("nav.goodsManagement.promotionalOrder")
        } else if (data?.is_user_cunpon) {
            orderType = intl.get("nav.goodsManagement.promotionalCoupon")
        } else {
            orderType = intl.get("nav.goodsManagement.ordinaryOrder")
        }

        return (
            <div>
                <Card>
                    <Descriptions title={intl.get('subscription.info.base')}>
                        {
                            data?.spu_discount_id > 0 &&
                            <Descriptions.Item label={intl.get('nav.goodsManagement.promotionalgoods.discount_type')}>
                                {
                                    data?.spu_many_help_id > 0 ? intl.get("nav.goodsManagement.Promotion") + " " + intl.get("nav.goodsManagement.cutgoodsType") : intl.get("nav.goodsManagement.Promotion")
                                }
                            </Descriptions.Item>
                        }
                        {
                            data?.spu_discount_id > 0 &&
                            <Descriptions.Item label={intl.get('nav.goodsManagement.promotionalgoods_name')}>
                                {
                                    data?.orderSpu?.data[0]?.attach_data?.spu_discount.name
                                }
                            </Descriptions.Item>
                        }
                        {
                            data?.spu_discount_id > 0 &&
                            <Descriptions.Item label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_fixed')}>
                                {
                                    spu_discount && spu_discount.discount_type === "fixed" ? currency_symbol + spu_discount.discount_on_fixed : parseFloat(spu_discount.discount_on_percentage) * 100 + "%"
                                }
                            </Descriptions.Item>
                        }
                        <Descriptions.Item label={intl.get('home.table.ordernumber')}>{data?.order_sn}</Descriptions.Item>
                        <Descriptions.Item label={intl.get("home.table.ordertime")}>{data?.created_at?.date && data.created_at.date.substring(0, 19)}</Descriptions.Item>
                        <Descriptions.Item label={intl.get('home.table.status')}>{data?.is_pay === 0 ? intl.get('home.table.status.name_n') : intl.get('home.table.status.name_y')}</Descriptions.Item>
                         
                        
                         
                        <Descriptions.Item label={intl.get("home.table.time")}>{data && data.pay_at && data.pay_at.date.substring(0, 19)}</Descriptions.Item>

                        <Descriptions.Item label={intl.get("home.table.payee")}>{data && data.payee && data.payee.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}</Descriptions.Item>
                        {
                            data && data.pay_type &&
                            <Descriptions.Item label={intl.get("home.table.payment_method")}>{data.pay_type}</Descriptions.Item>
                        }
                        <Descriptions.Item label={intl.get('orders.status')}>{order_status}</Descriptions.Item>
                        {
                            data?.scan_code_type > 0 &&
                            <Descriptions.Item label={intl.get('home.table.scanning_mode')}>
                                {scan_code_type}
                            </Descriptions.Item>
                        }
                        {
                            data && data.order_receiving_at && data.order_receiving_at.date &&
                            <Descriptions.Item label={intl.get("setup.form.order_time")}>{data.order_receiving_at.date.substring(0, 19)}</Descriptions.Item>
                        }
                        {
                            data && data.service_end_at && data.service_end_at.date &&
                            <Descriptions.Item label={intl.get("home.table.dissipate")}>{data && data.service_end_at && data.service_end_at.date.substring(0, 19)}</Descriptions.Item>
                        }
                        

                        {
                            data?.remark &&
                            <Descriptions.Item label={intl.get('order.table.remark')}>{data?.remark}</Descriptions.Item>
                        }
                         





                    </Descriptions>
                    <Divider />
                    <Descriptions title={intl.get('user.table.user')}>
                        <Descriptions.Item label="uuid">{data && data.user && data.user.uuid}</Descriptions.Item>
                        <Descriptions.Item label={intl.get('user.table.nickname')}>{data && data.user && data.user.name}</Descriptions.Item>
                        <Descriptions.Item label={intl.get('user.table.phone')}>{data && data.user && data.user.phone}</Descriptions.Item>
                        {
                            data.partner && data.partner.is_send_receipt === 1 &&
                            <Descriptions.Item label={intl.get("setup.form.email")}>{data && data.user && data.user.email}</Descriptions.Item>
                        }
                    </Descriptions>
                    {
                        data && data.address &&
                        <Divider />
                    }
                    {
                        data && data.address &&
                        <Descriptions title={intl.get("home.table.address")}>
                            <Descriptions.Item label={intl.get("nav.CostFlow.name")}>{data.username}</Descriptions.Item>
                            <Descriptions.Item label={intl.get("user.table.phone")}>{data.phone}</Descriptions.Item>
                            <Descriptions.Item label={intl.get("home.table.address")}>{data.address}</Descriptions.Item>
                        </Descriptions>
                    }
                    <Divider />





                    {
                     
                        <div>
                            <Descriptions title="盲盒信息"> 
                                
                                <Descriptions.Item label="封面"> 
                                <img style={{ width: "50px", height: "50px", objectFit: 'cover' }}  src={data && data.blindstore && data.blindstore.image} alt="" />
                                </Descriptions.Item> 

                                <Descriptions.Item label="名称">{data && data.blindstore && data.blindstore.name}</Descriptions.Item> 
                                <Descriptions.Item label="价格">$9.00</Descriptions.Item>  
                            </Descriptions>
                            <Divider />
                        </div>
                    }
                    




                    {
                        userCoupon &&
                        <div>
                            <Descriptions title={intl.get('home.table.order_coupon')}>
                                <Descriptions.Item label={intl.get("nav.goodsManagement.promotionalgoods.coupon") + "id"}>{userCoupon.coupon.id}</Descriptions.Item>
                                <Descriptions.Item label={intl.get('nav.goodsManagement.promotionalgoods.coupon_name')}>{userCoupon.coupon.name}</Descriptions.Item>
                                <Descriptions.Item label={intl.get('nav.goodsManagement.promotionalgoods.coupon') + intl.get("plugin.status")}>{coupon_status}</Descriptions.Item>
                                <Descriptions.Item label="Ticket code">{userCoupon.bar_code}</Descriptions.Item>
                            </Descriptions>
                            <Divider />
                        </div>
                    }

                    <Descriptions title={intl.get("nav.CostFlow.cost")}> 
                        <Descriptions.Item label={intl.get("home.table.order_price")}>{currency_symbol}{data && parseFloat(data.price).toFixed(2)}</Descriptions.Item>
                        <Descriptions.Item label={intl.get("home.table.bank")}>{currency_symbol}{data && data.transaction_fee}</Descriptions.Item>
                        <Descriptions.Item label={intl.get("home.table.totalprice")}>{currency_symbol}{data && data.pay_total_price}</Descriptions.Item>
                       
 
                        <Descriptions.Item label="GST">{currency_symbol}{order_gst}</Descriptions.Item>

                    </Descriptions>
                    {
                        data?.order_status === "refunded" && <Divider />
                    }
                    {
                        data?.order_status === "refunded" &&
                        <Descriptions title={intl.get("home.table.useStatus.refunded_detail")}>

                            <Descriptions.Item label={intl.get("home.table.useStatus.refunded_to")}>
                                {
                                    data?.is_refunded == 1 ?
                                        <Button
                                            type='link'
                                            onClick={() => {
                                                this.setState({
                                                    is_show: true
                                                })
                                            }}
                                        >
                                            {intl.get("home.table.useStatus.bank_card")}
                                        </Button>
                                        : data?.is_pay == 2 ? intl.get("home.table.useStatus.wallet") : ""
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={intl.get("home.table.useStatus.refunded_time")}>
                                {
                                    data?.is_refunded == 1 ? data?.user_withdraw_at?.date.substring(0, 19) : data?.is_pay == 2 ? data?.refund_at?.date.substring(0, 19) : ""
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={intl.get("home.table.useStatus.refunded_amount")}>
                                ${data?.refund_price}
                            </Descriptions.Item>
                        </Descriptions>
                    }
                    {
                        data?.message &&
                        <div>
                            <Divider />
                            <div>
                                <p style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "bold", fontSize: "16px" }}>{intl.get("home.table.useStatus.orderReply")}</p>
                                <p>{data?.message}</p>
                            </div>
                        </div>
                    }



                    <Divider />
                    
                    

                    <Modal
                        visible={this.state.is_show}
                        footer={null}
                        onCancel={() => this.setState({
                            is_show: false
                        })}
                    >
                        {
                            oneUserWithdrawLog &&
                            <Descriptions
                                column={2}
                                title={intl.get("home.table.order_refund.information")}
                            >
                                <Descriptions.Item label={intl.get("home.table.order_refund.name")}>{oneUserWithdrawLog.user_name}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.bank")}>{oneUserWithdrawLog.account_name}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.account")}>{oneUserWithdrawLog.account}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.abn")}>{oneUserWithdrawLog.abn}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.bsb")}>{oneUserWithdrawLog.bsb}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.price")}>{oneUserWithdrawLog.money}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.message")}>{oneUserWithdrawLog.dispose_message}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.type")}>{oneUserWithdrawLog.is_finish}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.img")}>{oneUserWithdrawLog.ticket_image && <img src={oneUserWithdrawLog.ticket_image} style={{ width: "100px" }} />}</Descriptions.Item>
                            </Descriptions>
                        }

                    </Modal>
                </Card>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.return')}
                        </Button>
                    </Popconfirm>

                </div>
            </div>


        );
    }
}

export default withRouter(OrderDetail)

