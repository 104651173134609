import React from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { Form, Input, InputNumber, Button, Select, Radio, DatePicker, message, Switch, Upload, Icon, Tooltip, Checkbox, Popconfirm } from 'antd';
import moment from 'moment-timezone';
import { getCouponDetail, putCoupon, getNoSkuGoodsList, getGoodsDetail, getBlindboxDetail,putBlindbox } from "../../action/goodAction";
import { GetConfigSet, getPartner, getLangList ,getSalesAreaSubList} from "../../action/authAction";
import { getEditorContent } from "../../action/AdAction";
import intl from 'react-intl-universal';
import BraftEditor from 'braft-editor'
import { getLangType } from "../../util/function";
const { Option } = Select;
const { RangePicker } = DatePicker;

let currency_symbol = sessionStorage.getItem("currency_symbol")

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}

class EditBlindBox extends React.Component {
    state = {
        name: "",
        SearchData: [],
        spu_id: "",
        discount_type: "fixed",
        image_type: "default",
        coupon_type: "",
        price_type: "price",
        date: "",
        data: "",
        imageUrl: "",
        logo_file: "",
        min_pay_fee_on_online: "",
        is_show_spu_many_help: false,
        is_show: false,
        offState: false,
        editorState: "",
        goods: "",
        disable_language: true,
        is_disable_language: false,
        qrcode: "",
        typeData: [
            // {
            //     id: 0,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.inside")
            // },
            // {
            //     id: 1,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.external_coupon")
            // },
            // {
            //     id: 2,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.mysterious")
            // }
        ],
        positionData: [
            {
                value: 1,
                label: intl.get("nav.goodsManagement.promotionalgoods.on-line"),
                disabled: false
            },
            {
                value: 0,
                label: intl.get("nav.goodsManagement.promotionalgoods.Offline")
            },

        ],
        operation_type: [],
    };

    async componentDidMount() {
        const id = this.props.location.pathname.substring(14)

         let SalesAreaSubData = await getSalesAreaSubList();//销售小区


        let configData = await GetConfigSet()
        let PartnerData = await getPartner()
        let language = await getLangList()
        let languageData = language.data.data
        let languageList = []
        for (let i in languageData) {
            if (languageData[i].is_use === 1) {
                languageList.push(languageData[i])
            }
        }
        getBlindboxDetail(id).then(res => {
            if (res.data.spu_id !== 0) {
                this.HandleGetSpuProduct(res.data.spu_id)
            }

            this.ChangeEditorData(res)
            let goods = res.data.spu
            let is_authorization_code = PartnerData.data.is_authorization_code === 1
            let positionData = [...this.state.positionData]
            positionData[0].disabled = is_authorization_code ? false : true
            if (res.data.qrcode) {
                this.HandleGetQrcode(res.data.qrcode)
            }
            this.setState({
                data: res.data,
                goods: res.data.spu,
                imageUrl: res.data.image,
                image_type: res.data.image_type === 0 ? "default" : "self",
                discount_type: res.data.discount_type,
                //销售小区列表
                typeData:  SalesAreaSubData.data.data ,
                price_type: res.data.price_type,
                disable_language: res.data.disable_language !== 1,
                spu_id: res.data.spu_id,
                is_show: res.data.is_show,
                is_disable_language: languageList.length === 1 ? true : false,
                offState: res.data.state === 1,
                date: [moment(res.data.start_at, "YYYY-MM-DD HH:mm:ss"), moment(res.data.end_at, "YYYY-MM-DD HH:mm:ss")],
                min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,
                is_show_spu_many_help: PartnerData.data.is_show_spu_many_help === 1,
                editorState: BraftEditor.createEditorState(res.data.detail),
                positionData: positionData,
                coupon_type: res.data.spu_id !== 0 ? "goods" : "shop",
                operation_type: res.data.operation_type.toString() && (res.data.operation_type !== 2 ? [res.data.operation_type] : [0, 1])
            })
        })
    }

    HandleGetSpuProduct = (id) => {
        getBlindboxDetail(id).then(res => {
            this.setState({
                name: res.data.name
            })
        })
    }
    handleSubmit = (e) => {
        const id = this.props.location.pathname.substring(14)
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            let formData = new FormData()
            let { spu_id, discount_type, price_type, logo_file, coupon_type, is_show, operation_type, offState, image_type, editorState } = this.state
            let date = this.props.form.getFieldValue('date');

            // if (coupon_type === "goods") {
            //     if (spu_id) {
            //         values.spu_id = spu_id
            //     } else {
            //         message.error(intl.get('nav.goodsManagement.promotionalgoods.selectError'))
            //     }

            // } else {
            //     values.spu_id = "0"
            // }


            values.discount_type = discount_type
            values.price_type = price_type
            values.image_type = image_type === "default" ? "0" : "1"
            values.operation_type = operation_type.length > 1 ? "2" : operation_type
            values.type = "1"
            values.disable_language = this.state.disable_language ? "0" : "1"
            values.detail = this.state.editorState.toHTML()
            values.is_show = "1"
            values.state = offState ? "1" : "0"
            values.start_at = values.date ? moment(date[0]).format("YYYY-MM-DD") + " 00:00:00" : ""
            values.end_at = values.date ? moment(date[1]).format("YYYY-MM-DD") + " 23:59:59" : ""
            delete values.good_image
            delete values.goods_rules
            delete values.date
            if (values.discount_on_percentage) {
                values.discount_on_percentage = parseInt(values.discount_on_percentage) / 100
            }

            if (values.quota) {
                if (parseInt(values.quota) > parseInt(values.stock)) {
                    message.error(intl.get("nav.goodsManagement.promotionalgoods.quota_cannot_stock"))
                    return false
                }
            }

            if (!err) {
                for (let i in values) {
                    formData.append(i, values[i])
                }
                logo_file && formData.append("image_file", logo_file)
                putBlindbox(formData, id).then(res => {
                    this.props.history.push("/BlindBoxList")
                })

            }

        });
    };
    onChange = e => {
        this.setState({
            value: e
        });
    };
    onSelectSearch = (val) => {
        if (val.length > 0) {
            getNoSkuGoodsList(1, val).then(res => {
                this.setState({
                    SearchData: res.data.data
                })
            })
        }

    }
    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };

    checkPsd(rule, value, callback) {
        let price = this.state.data.spu && this.state.data.spu.price;
        let splitData = value.toString().split(".")
        let num = splitData.length === 1 ? 1 : splitData[1].length
        if (price && parseInt(price) < parseInt(value)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.discountPrice")));
        } else if (this.accSub(price, value) < parseInt(this.state.min_pay_fee_on_online)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.minimumOrder")));
        } else if (num > 2) {
            callback(new Error(intl.get("goods.list.goodsAnnexType.goodsAnnex_price.error")));
        } else {
            callback();
        }
    }

    checkPsd2(rule, value, callback) {
        let price = this.state.data.spu.price;
        if (this.accMul(price, value) / 100 < parseInt(this.state.min_pay_fee_on_online)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.minimumOrder")));
        } else {
            callback();
        }
    }
    checkStock(rule, value, callback) {
        let stock = this.state.data.stock;
        if (stock && parseInt(stock) > parseInt(value)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.stock_error")));
        } else {
            callback();
        }
    }

    checkFullPrice(rule, value, callback) {
        let discount_on_fixed = this.props.form.getFieldValue('discount_on_fixed')
        let notOk = parseFloat(value) < discount_on_fixed + 1
        if (notOk) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.full_price_error")));
        } else {
            callback();
        }
    }

    // 除以
    accDiv(arg1, arg2) {
        let t1 = 0,
            t2 = 0,
            r1, r2;
        try {
            t1 = arg1.toString().split(".")[1].length
        } catch (e) { }
        try {
            t2 = arg2.toString().split(".")[1].length
        } catch (e) { };
        r1 = Number(arg1.toString().replace(".", ""))
        r2 = Number(arg2.toString().replace(".", ""))
        return (r1 / r2) * Math.pow(10, t2 - t1);
    }
    // 乘以
    accMul(arg1, arg2) {

        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length
        } catch (e) { }
        try {
            m += s2.split(".")[1].length
        } catch (e) { }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }
    // 减
    accSub(arg1, arg2) {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //动态控制精度长度 
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    }

    ChangeEditorData = (data) => {
        getEditorContent(data.data.detail).then(res => {
            this.setState({
                editorState: BraftEditor.createEditorState(res.data)
            })
        }).catch(error => {
        })
    }

    HandleGetQrcode = (url) => {
        axios.get(url).then(res => {
            this.setState({
                qrcode: res.data
            })
        })
    }

    render() {
        const { name, qrcode, SearchData, discount_type, spu_id, data, goods, operation_type, date, price_type, positionData, coupon_type, image_type, imageUrl, is_show, offState, is_show_spu_many_help, editorState, typeData, is_disable_language, disable_language } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );
        if (data) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView" style={{ minWidth: "1000px" }}>
                    {/*<Form.Item
                        label="CDID"
                    >
                        <Input disabled value={data.adid} />
                    </Form.Item>*/}


                    <Form.Item
                        label="盲盒名称"
                    >
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods_name_error") }],
                            initialValue: data.name
                        })(<Input />)}
                    </Form.Item>


                     
                    {
                        
                        <Form.Item
                            label={<span>
                                封面
                                <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>

                            </span>}
                        >
                            <Upload
                                name="avatar"
                                accept=".jpg,.png,.jpeg"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={this.handleChange}
                            >
                                {!!imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                    }
 

 
                    {
                        !is_disable_language &&
                        <Form.Item label={<span>{intl.get('goods.list.disableLanguage')}</span>}>
                            <Switch checked={disable_language}
                                onChange={() => {
                                    this.setState({
                                        disable_language: !disable_language
                                    })
                                }} />
                        </Form.Item>
                    }


 
                    <Form.Item
                        label={<span>开始时间</span>}
                    >
                        {getFieldDecorator('date', {
                            rules: [{ required: true }],
                            initialValue: date
                        })(<RangePicker
                            format="DD/MM/YYYY"
                            disabledDate={(current) => {
                                return current < moment().endOf('day')
                            }}
                        />)}
                    </Form.Item>


                    <Form.Item
                    label="销售小区"
                >
                    {getFieldDecorator('sales_area_sub_id', {
                        rules: [{ required: true }],
                        initialValue: data.sales_area_sub_id
                    })(<Select
                         
                    >
                        {typeData && typeData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                    </Select>)}
                </Form.Item>


                <Form.Item
                    label="Ticket数量"
                >
                    {getFieldDecorator('total_stock', {
                        rules: [{ required: true, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }, { validator: (rule, value, callback) => { this.checkStock(rule, value, callback) } }],
                        initialValue: 1000
                    })(<InputNumber max={1000} style={{ width: "100%" }}  disabled />)}
                </Form.Item>





                <Form.Item
                    label="盲盒价格"
                >
                    {getFieldDecorator('price', {
                        rules: [{ required: true, message: '请选择价格类型'}],
                        initialValue: data.price
                    })(<Input  readonly disabled  prefix={currency_symbol}  />)}
                </Form.Item>



                   
                    <Form.Item
                        label={intl.get('goods.list.sort')}
                    >
                        {getFieldDecorator('sort', {
                            rules: [{ required: false }],
                            initialValue: data.sort
                        })(<Input type="number" />)}
                    </Form.Item>


 
                    
                    <Form.Item
                        label="盲盒详情"
                    >
                        <div>
                            <div style={{
                                border: "1px solid #dedede",
                            }}>
                                <BraftEditor
                                    value={editorState}
                                    language={Number(getLangType()) == 1 ? "zh" : "en"}
                                    onChange={(editorState) => {
                                        this.setState({ editorState })
                                    }} />
                            </div>
                            <p style={{ color: "#ccc", lineHeight: "20px" }}>{intl.get("nav.goodsManagement.coupon.detail_tips")}</p>
                        </div>
                    </Form.Item>


                    <Form.Item {...tailFormItemLayout}>
                        <Popconfirm
                            title={intl.get('seat.table.btn.cancel.placeHorder')}
                            onConfirm={() => {
                                this.props.history.goBack()
                            }}>
                            <Button
                                type="primary"
                                style={{ marginRight: "10px" }}
                            >
                                {intl.get('seat.table.btn.cancel')}
                            </Button>
                        </Popconfirm>
                        <Button type="primary" htmlType="submit">
                            {intl.get('cashwithdrawal.form.submit')}
                        </Button>
                    </Form.Item>


                </Form >
            );
        } else {
            return null
        }

    }
}

export default withRouter(Form.create()(EditBlindBox))
